
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'
@Component
export default class ImportData extends Vue {
  @Prop({ default: '' }) private templateKey!: string
  @Prop({ default: false }) private isShowImport!: boolean
  @Prop({ default: '' }) private projectId!: string // 项目id

  private headInfo = {
    token: window.sessionStorage.getItem('token') || '',
    customer: window.sessionStorage.getItem('customerCode') || ''
  }

  private fullscreenLoading = false
  private title = '导入'
  private templateUrl = ''
  private isShowtable = false
  private resultList = []
  private ruleForm = {}

  private resData = {
    success: 0,
    error: 0
  }

  created (): void {
    this.getTemplateUrl()
  }

  async onChange (file: any) {
    // console.log('file', file)
  }

  // 文件上传之前
  beforeUpload (file: ElUploadInternalFileDetail) {
    const size = file.size / 1024 / 1024 < 10
    const type = 'xls,xlsx'
    const name = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    if (type.indexOf(name) === -1) {
      this.$message({
        message: '上传文件只能是 .xls,.xlsx 格式!',
        type: 'warning'
      })
      return false
    } else if (!size) {
      this.$message({
        message: '上传文件大小不能超过 10MB!',
        type: 'warning'
      })
      return false
    } else {
      this.fullscreenLoading = true
      return true
    }
  }

  // 文件上传失败
  fileError (error: ErrorEvent) {
    this.fullscreenLoading = false
    this.$message.error(JSON.parse(error.message).message)
  }

  successFile (res: any) {
    // console.log('res', res)
    this.fullscreenLoading = false
    if (res.data) {
      this.isShowtable = true
      this.title = '导入结果'
      if (res.data.error === 0) {
        // this.$emit('update:isShowImport', false)
        this.$emit('onSuccess', res)
        this.$message.success('已成功导入' + res.data.success + '条，失败0条')
        this.resData = {
          error: res.data.error || 0,
          success: res.data.success || 0
        }
        this.resultList = []
      } else {
        this.resData = {
          error: res.data.error || 0,
          success: res.data.success || 0
        }
        this.$message.success('已成功导入' + res.data.success + '条，失败' + res.data.error + '条')
        this.$emit('onSuccess', res)
        this.resultList = res.data.errorList || []
      }
    } else {
      this.$message.error(res.message)
    }
  }

  getTemplateUrl () {
    this.$axios.get(this.$apis.common.selectTemplate, { templateKey: this.templateKey }).then(res => {
      if (!res) {
        this.$message.error('请先配置导入模板')
      } else {
        this.templateUrl = res
      }
    })
  }

  // 取消
  closeDialog () {
    this.title = '导入'
    this.isShowtable = false
    this.$emit('update:isShowImport', false)
    this.$nextTick(() => {
      this.resultList = []
      this.resData = {
        success: 0,
        error: 0
      }
    })
  }
}
