
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { PlanDetail, TaskDetail, PersonDetail } from '@/types/plan.d'
import { OrderTypeDetail } from '@/types/newOrder'
import { ProjectUser } from '@/types/project'
import dayjs from 'dayjs'
import ImportData from './export.vue'
@Component({
  components: { ImportData },
  filters: {
    filterProcessorName (list: Array<PersonDetail>) {
      // console.log('list', list)
      let val = ''
      list.forEach(item => {
        val += item.currentProcessorName + ','
      })
      return val.length ? val.substr(0, val.length - 1) : ''
    }
  }
})
export default class Add extends Vue {
  @Watch('taskInfo.taskTime', { immediate: false })
  dictTime (val: string) {
    if (val) {
      const date = dayjs().format('YYYY-MM-DD')
      const hours = dayjs().format('HH')
      const defaultDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
      if (val.split(' ')[0] === date) {
        this.taskInfo.taskTime = defaultDate + ' ' + hours
      }
    }
  }

  private isShowImport = false

  private info: PlanDetail = {
    projectId: '',
    planMonth: '',
    introduce: '',
    taskList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    planMonth: [
      { required: true, message: '请选择计划月份', trigger: 'change' }
    ],
    introduce: [
      { required: true, message: '请输入计划说明详情', trigger: 'change' }
    ]
  }

  // 添加任务
  private state = 'add'
  private addShow = false
  private typeList: Array<OrderTypeDetail> = []
  private projectUserList: Array<ProjectUser> = []
  private taskInfo: TaskDetail = this.initTaskInfo

  private index: number|null = null

  private taskRules = {
    taskDetails: [
      { required: true, message: '请输入任务详情', trigger: ['blur', 'change'] }
    ],
    startDate: [
      { required: true, message: '请选择任务日期', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: ['blur', 'change'] }
    ],
    projectList: [
      { required: true, message: '请添加工单指派', trigger: ['blur', 'change'] }
    ],
    taskPattern: [
      { required: true, message: '请选择下发模式', trigger: ['blur', 'change'] }
    ],
    taskTime: [
      { required: true, message: '请选择下发条件', trigger: ['blur', 'change'] }
    ]
  }

  private date: string[] = []

  private loading = false

  private pickerOptions = {
    disabledDate: (time: {getTime: Function}) => {
      return time.getTime() < Date.now() - 8.64e7
    }
  }

  // 指派人添加
  private addPersonLine: number | null = null
  private personInfo: PersonDetail = this.initPersonInfo

  private personInfoRules = {
    currentProcessor: [
      { required: true, message: '请选择指派人', whitespace: true }
    ],
    workArea: [
      { required: true, message: '请输入作业区域', trigger: 'change' }
    ]
  }

  private taskPickerOptions = {
    disabledDate: (time: {getTime: Function}) => {
      if (this.maxTime) {
        return time.getTime() < Date.now() + 60 * 60 || time.getTime() > this.maxTime
      } else {
        return time.getTime() < Date.now()
      }
    }
  }

  private submitShow = false

  get maxTime () {
    let maxTime = 0
    const time = this.info.planMonth.split('-')
    if (time.length > 0) {
      let year = Number(time[0])
      let month = Number(time[1])
      if (month === 12) {
        month = 1
        year = year + 1
      } else {
        month = month + 1
      }
      maxTime = new Date(year + '/' + month + '/1').getTime() - 24 * 60 * 60
    }
    return maxTime
  }

  get projectList () {
    return this.$store.state.projectList
  }

  // 初始化单条任务信息
  get initTaskInfo () {
    return {
      taskDetails: '',
      startDate: '',
      endDate: '',
      orderType: '',
      taskPattern: '1',
      taskTime: '',
      projectList: []
    }
  }

  // 初始化单个工单指派
  get initPersonInfo () {
    return {
      currentProcessor: '',
      currentProcessorName: '',
      workArea: ''
    }
  }

  getOrderTypeName (type: string) {
    const index = this.typeList.findIndex(item => item.orderType === type)
    return index >= 0 ? this.typeList[index].orderTypeName : ''
  }

  created () {
    this.getTypeList()
  }

  getTypeList () {
    this.$axios.get<{ list: Array<OrderTypeDetail> }>(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    console.log(this.info.projectId)
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.projectUserList = res.list || []
    })
  }

  // 新增任务
  onAddTask () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.state = 'add'
        this.addShow = true
      } else {
        this.$message.warning('请先完善计划信息')
      }
    })
  }

  // 导入任务
  onExport () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.isShowImport = true
      } else {
        this.$message.warning('请先完善计划信息')
      }
    })
  }

  // 修改任务
  onUpdateTask (row: any, index: number) {
    this.state = 'update'
    this.index = index
    this.addShow = true
    this.taskInfo = row
    this.date = [row.startDate, row.endDate]
  }

  // 删除任务
  onDeleteTask (index: number) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.info.taskList.splice(index, 1)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 添加任务保存
  onSubmitTask () {
    (this.$refs.taskInfo as ElForm).validate(valid => {
      if (valid) {
        if (this.addPersonLine === null) {
          if (this.state === 'add') {
            this.info.taskList.push(this.taskInfo)
          } else {
            // this.info.taskList[this.index] = JSON.parse(JSON.stringify(this.taskInfo)) // 这个方法不能实时更新数据
            this.info.taskList.splice(this.index as any, 1, JSON.parse(JSON.stringify(this.taskInfo)))
          }
          this.onCancelTask()
        } else {
          this.$message.warning('请先保存工单指派信息')
        }
      }
    })
  }

  // 导入数据加入表格中
  onExportResult (data: any) {
    // console.log('添加', data)
    const successList = data.data.successList.map((item: any) => {
      return {
        endDate: item.endDate,
        startDate: item.startDate,
        orderType: item.orderType,
        taskDetails: item.taskDetails,
        taskPattern: item.taskPattern,
        taskTime: item.taskTime,
        projectList: JSON.parse((item.userObj))
      }
    }) || []
    this.info.taskList.push(...successList)
    // console.log('处理后数据', this.info.taskList)
  }

  // 取消任务添加或修改
  onCancelTask () {
    this.taskInfo = JSON.parse(JSON.stringify(this.initTaskInfo))
    this.addPersonLine = null
    this.date = []
    this.$nextTick(() => {
      (this.$refs.taskInfo as ElForm).clearValidate()
    })
    this.addShow = false
  }

  // 新增工单指派
  onAddPerson (): void {
    if (this.addPersonLine === null) {
      this.addPersonLine = this.taskInfo.projectList.length
      this.taskInfo.projectList.push(this.initPersonInfo)
    } else {
      this.$message.warning('请先保存正在编辑的工单指派')
    }
  }

  // 编辑工单指派
  addPersonEdit (index: number, row: PersonDetail): void {
    if (this.addPersonLine === null) {
      this.addPersonLine = index
      this.personInfo = JSON.parse(JSON.stringify(row))
    } else {
      this.$message.warning('请先保存正在编辑的工单指派')
    }
  }

  // 保存工单指派
  addPersonSave () {
    (this.$refs.addPerson as ElForm).validate(valid => {
      if (valid) {
        const index = this.addPersonLine as number
        this.personInfo.currentProcessorName = this.projectUserList.filter(item => item.userId === this.personInfo.currentProcessor)[0].name
        // this.taskInfo.projectList[index] = JSON.parse(JSON.stringify(this.personInfo))
        this.taskInfo.projectList.splice(index, 1, JSON.parse(JSON.stringify(this.personInfo)))
        this.personInfo = JSON.parse(JSON.stringify(this.initPersonInfo))
        this.addPersonLine = null
      }
    })
  }

  // 删除工单指派指定行
  addPersonDelete (index: number) {
    this.taskInfo.projectList.splice(index, 1)
    this.addPersonLine = null
  }

  // 时间
  changetimers (val: string[]) {
    if (val && val[0] && val[1]) {
      this.taskInfo.startDate = val[0]
      this.taskInfo.endDate = val[1]
    } else {
      this.taskInfo.startDate = ''
      this.taskInfo.endDate = ''
    }
  }

  onSubmit () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        if (this.info.taskList.length) {
          this.submitShow = true
          this.$axios.post(this.$apis.plan.insertFlowPlans, this.info).then(() => {
            this.$message.success('保存成功')
            this.$router.push({ name: 'planList' })
          }).finally(() => {
            this.submitShow = false
          })
        } else {
          this.$message.warning('请添加任务信息')
        }
      } else {
        (this.$refs.taskInfo as ElForm).clearValidate()
      }
    })
  }
}
